import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Carousel } from 'components/carousel/Carousel';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { IAdditionalService } from 'components/additionalServicesList/interfaces/IAdditionalService';
import { AdditionalServiceCard } from 'components/additionalServicesList/partials/AdditionalServiceCard';

interface IAdditionalServicesCarousel {
    services: IAdditionalService[];
    showMobilePagination: boolean;
}

export const AdditionalServicesCarousel: FC<IAdditionalServicesCarousel> = ({
    services,
    showMobilePagination,
}) => {
    return (
        <Box pl={2}>
            <Carousel
                spaceBetween={8}
                showingCountOfItems="auto"
                showPagination={showMobilePagination}
                items={services.map((info, index) => (
                    <Box key={`${info.url}-${index}`}>
                        <AdditionalServiceCard
                            icon={info.icon}
                            name={info.name}
                            gtmLabel={info.gtmLabel}
                            url={
                                AppConfigService.getNehnutelnostiUrl() +
                                info.url
                            }
                        />
                    </Box>
                ))}
            />
        </Box>
    );
};
