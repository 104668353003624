import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    Tabs
} from 'components/page/homePage/components/filter/partials/content/partials/tabs/Tabs';
import {
    TabContent
} from 'components/page/homePage/components/filter/partials/content/partials/tabContent/TabContent';
import {
    IInitialSearchFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import { EActiveTab } from 'components/page/homePage/enums/filter/tabs/EActiveTab';

interface IContent {
    baseFilterLocations: ILocation[];
    initialSearchFilterData: IInitialSearchFilterData;
}

export const Content: FC<IContent> = ({
    baseFilterLocations,
    initialSearchFilterData,
}) => {
    const [activeTab, setActiveTab] = useState<EActiveTab>(EActiveTab.ADVERTISEMENTS);
    // NOTE: prevent hidden faces in specific resolutions.
    const [, setWindowHeight] = useState(0);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setWindowHeight(window.innerHeight);
        }

        const handleWindowResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const onChangeActiveTab = (newTab: EActiveTab) => {
        setActiveTab(newTab);
    };

    return <Box
        top={{
            xs: 90,
            md: '40%',
            lg: '50%'
        }}
        left='50%'
        width='100%'
        display='flex'
        alignItems='center'
        position='absolute'
        flexDirection='column'
        sx={{
            transform: {
                xs: 'translate(-50%, 0%)',
                md: 'translate(-50%, -50%)',
            },
        }}
    >
        <Box
            mb={{ xs: 2, md: 3 }}
            maxWidth={{ xs: 300, sm: '100%' }}
            sx={{ textShadow: '0 0 4px rgba(0,0,0,0.4)'}}
        >
            <Text
                center
                semibold
                variant='h1'
                mobileVariant='h4'
                color={(palette) => palette.labelPrimaryInverse}
                component='h1'
            >
                Vybrať správne je&nbsp;kľúčové
            </Text>
        </Box>

        <Box mb={1.5}>
            <Tabs
                activeTab={activeTab}
                onChangeActiveTab={onChangeActiveTab}
            />
        </Box>

        <Box
            width='100%'
            paddingX={4}
            display='flex'
            justifyContent='center'
        >
            <TabContent
                activeTab={activeTab}
                baseFilterLocations={baseFilterLocations}
                initialSearchFilterData={initialSearchFilterData}
            />
        </Box>
    </Box>;
};