import { FC, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { useRouter } from 'next/navigation';
import { appUserIsLoggedIn } from 'modules/state/app/state';
import { Box } from '@mui/material';
import {
    Location
} from 'components/advertisementsFilter/components/implementation/homePage/partials/common/locationSearch/partials/Location';
import { useHomePageLocations } from 'components/advertisementsFilter/hooks/location/homePage/useHomePageLocations';

import {
    SearchButton
} from 'components/advertisementsFilter/components/common/desktopFilter/partials/searchButton/SearchButton';
import { getLocationSearchEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { usePopover } from 'components/advertisementsFilter/hooks/popover/usePopover';
import { REAL_ESTATE_AGENTS_LISTING, SEARCH_MAP_ID } from 'modules/route/routes';
import { ETabType } from 'components/page/homePage/enums/filter/tabs/ETabType';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { EAction } from 'modules/gtmEvents/enums/homePage/EAction';

interface ILocationSearch {
    type: ETabType;
    baseFilterLocations: ILocation[];
}

export const LocationSearch: FC<ILocationSearch> = ({
    type,
    baseFilterLocations,
}) => {
    const router = useRouter();

    const [isAdvertiserLoggedIn] = useRecoilState(appUserIsLoggedIn);

    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();

    const {
        options,
        locationQuery,
        onChangeQuery,
        selectedLocation,
        onChangeLocation,
    } = useHomePageLocations(baseFilterLocations);
    const {
        anchorEl: anchorElLocation,
        onOpen: opeOpenLocation,
        isOpen: isOpenLocation,
        onClose: onCloseLocation
    } = usePopover();

    const onRedirectByType = useCallback(() => {
        let pathname = '';
        if (type === ETabType.AGENTS) {
            pathname = REAL_ESTATE_AGENTS_LISTING(selectedLocation?.sefName);
        }

        if (type === ETabType.NEW_BUILDINGS) {
            pathname = SEARCH_MAP_ID(selectedLocation?.sefName);
        }

        if (isAdvertiserLoggedIn !== null) {
            gtmSendEvent(getLocationSearchEvent(
                selectedLocation?.sefName,
                type ===  ETabType.AGENTS ? EAction.AGENTS : EAction.NEWBUILDINGS,
                isAdvertiserLoggedIn,
            ));
        }

        router.push(pathname);
    }, [router, type, isAdvertiserLoggedIn, selectedLocation, gtmSendEvent]);

    return <Box
        gap={{ xs: 0, md: 2 }}
        height={64}
        padding={1.2}
        display='flex'
        alignItems='center'
        borderRadius='99px'
        justifyContent='space-between'
        width={{ xs: '100%', md: 480 }}
        bgcolor={(theme) => theme.palette.backgroundPrimary.main}
        sx={(theme) => ({
            '&:hover': {
                background: theme.palette.backgroundTertiary.main
            }
        })}
    >
        <Location
            isSubFilter
            isInFocus={false}
            autocompleteOptions={options}
            locationQuery={locationQuery}
            isPopoverOpen={isOpenLocation}
            onOpenPopover={opeOpenLocation}
            onClosePopover={onCloseLocation}
            popoverAnchorEl={anchorElLocation}
            selectedLocation={selectedLocation}
            onChangeLocation={onChangeLocation}
            onChangeLocationQuery={onChangeQuery}
        />

        <SearchButton
            isTitleVisible={false}
            onClick={onRedirectByType}
        />
    </Box>;
};