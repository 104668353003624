import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Box } from '@mui/system';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

export const SubTitle: FC = () => {
    return (
        <Box whiteSpace={'pre-line'}>
            <Text
                variant={'body2'}
                component={'h4'}
                color={(palette) => palette.labelSecondary}
            >
                <Mobile>{'Vyberajte z aktuálnej ponuky \n nehnuteľnosti na trhu.'}</Mobile>
                <Desktop>{'Vyberajte z aktuálnej ponuky nehnuteľnosti na trhu.'}</Desktop>
            </Text>
        </Box>

    );
};
