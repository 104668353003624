import { FC, useMemo } from 'react';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    IInitialSearchFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import {
    HomePageSearchFilter
} from 'components/advertisementsFilter/components/implementation/homePage/HomePageSearchFilter';
import {
    LocationSearch
} from 'components/advertisementsFilter/components/implementation/homePage/partials/common/locationSearch/LocationSearch';
import { EActiveTab } from 'components/page/homePage/enums/filter/tabs/EActiveTab';
import { ETabType } from 'components/page/homePage/enums/filter/tabs/ETabType';

interface ITabContent {
    activeTab: EActiveTab;
    baseFilterLocations: ILocation[];
    initialSearchFilterData: IInitialSearchFilterData;
}

export const TabContent: FC<ITabContent> = ({
    activeTab,
    baseFilterLocations,
    initialSearchFilterData,
}) => {
    const baseFilterNewBuildingsLocations = useMemo(() => {
        return baseFilterLocations.filter((location) => !location.isForeign);
    }, [baseFilterLocations]);

    return <>
        {activeTab === EActiveTab.ADVERTISEMENTS &&
            <HomePageSearchFilter
                initialSearchFilterData={initialSearchFilterData}
            />
        }

        {activeTab === EActiveTab.AGENTS &&
            <LocationSearch
                type={ETabType.AGENTS}
                baseFilterLocations={baseFilterLocations}
            />
        }

        {activeTab === EActiveTab.NEW_BUILDINGS &&
            <LocationSearch
                type={ETabType.NEW_BUILDINGS}
                baseFilterLocations={baseFilterNewBuildingsLocations}
            />
        }
    </>;
};