import { FC } from 'react';
import {
    MobileFilterHeader
} from 'components/advertisementsFilter/components/implementation/homePage/partials/mobile/partials/filterHeader/FilterHeader';
import {
    FilterDialog
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/FilterDialog';
import { useMobileFilter } from 'components/advertisementsFilter/hooks/mobileFilter/useMobileFilter';
import {
    ITransaction
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import {
    ICategories
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';

interface IMobileFilter {
    height?: number;
    totalCount: number;
    locationQuery: string;
    selectedLocation: ILocation;
    categoryOptions: ICategories;
    selectedCategoryNames: string;
    isFetchingTotalCount: boolean;
    selectedCategoryIds: number[];
    onRedirectByFilter: () => void;
    onSearchFilterReset: () => void;
    selectedSubcategoryNames: string;
    selectedTransaction: ITransaction;
    transactionOptions: ITransaction[];
    locationOptionsInitial: ILocation[];
    onSelectCategory: (value: number) => void;
    onDeselectCategory: (value: number) => void;
    onChangeTransaction: (value: number) => void;
    onChangeLocationQuery: (value: string) => void;
    onChangeLocation: (location: ILocation) => void;
    autocompleteLocationOptions: IAutocompleteOption[];
}

export const MobileFilter: FC<IMobileFilter> = ({
    height,
    totalCount,
    locationQuery,
    categoryOptions,
    onSelectCategory,
    onChangeLocation,
    selectedLocation,
    onDeselectCategory,
    transactionOptions,
    onRedirectByFilter,
    selectedTransaction,
    onChangeTransaction,
    onSearchFilterReset,
    selectedCategoryIds,
    isFetchingTotalCount,
    onChangeLocationQuery,
    selectedCategoryNames,
    locationOptionsInitial,
    selectedSubcategoryNames,
    autocompleteLocationOptions,
}) => {
    const { isFilterOpened, toggleFilter } = useMobileFilter();

    return <>
        <MobileFilterHeader
            height={height}
            toggleFilter={toggleFilter}
            isFilterOpened={isFilterOpened}
            transaction={selectedTransaction}
            selectedCategoryNames={selectedCategoryNames}
            selectedLocationName={selectedLocation && selectedLocation.name}
        />

        {isFilterOpened &&
            <FilterDialog
                totalCount={totalCount}
                toggleFilter={toggleFilter}
                locationQuery={locationQuery}
                isFilterOpened={isFilterOpened}
                categoryOptions={categoryOptions}
                selectedLocation={selectedLocation}
                onChangeLocation={onChangeLocation}
                onSelectCategory={onSelectCategory}
                onDeselectCategory={onDeselectCategory}
                onRedirectByFilter={onRedirectByFilter}
                transactionOptions={transactionOptions}
                selectedTransaction={selectedTransaction}
                selectedCategoryIds={selectedCategoryIds}
                onChangeTransaction={onChangeTransaction}
                onSearchFilterReset={onSearchFilterReset}
                isFetchingTotalCount={isFetchingTotalCount}
                onChangeLocationQuery={onChangeLocationQuery}
                selectedCategoryNames={selectedCategoryNames}
                locationOptionsInitial={locationOptionsInitial}
                selectedSubcategoryNames={selectedSubcategoryNames}
                autocompleteLocationOptions={autocompleteLocationOptions}
            />
        }
    </>;
};