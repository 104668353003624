import { FC } from 'react';
import {
    MobileFilterWrapper
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/wrapper/FilterWrapper';
import {
    MultiInput
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterHeader/partials/multiInput/MultiInput';
import { ETransactionName } from 'components/advertisementsFilter/enums/transaction/ETransactionName';
import {
    ITransaction
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';

interface IMobileFilterHeader {
    height?: number;
    isFilterOpened: boolean;
    transaction: ITransaction;
    selectedLocationName: string;
    selectedCategoryNames: string;
    toggleFilter: (flag: boolean) => void;
}

export const MobileFilterHeader: FC<IMobileFilterHeader> = ({
    height,
    transaction,
    toggleFilter,
    isFilterOpened,
    selectedLocationName,
    selectedCategoryNames,
}) => {

    return <MobileFilterWrapper height={height}>
        <MultiInput
            isWithBackground
            isWithSearchButton
            location={selectedLocationName}
            category={selectedCategoryNames}
            onMobileFilterOpen={() => toggleFilter(!isFilterOpened)}
            transaction={transaction && ETransactionName[transaction.name]}
        />
    </MobileFilterWrapper>;
};