import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';
import {Palette, Theme, Button} from '@mui/material';
import { EActiveTab } from 'components/page/homePage/enums/filter/tabs/EActiveTab';

interface IOption {
    name: string;
    value: EActiveTab;
    isActive: boolean;
    disabled?: boolean;
    onClick: (value: EActiveTab) => void;
}

export const Option: FC<IOption> = ({
    name,
    value,
    onClick,
    disabled,
    isActive,
}) => {

    return (
        <Button
            fullWidth
            disableTouchRipple
            variant="text"
            color="inherit"
            disabled={disabled}
            onClick={() => !isActive && onClick(value)}
            sx={(theme: Theme) => ({
                height: 'initial',
                paddingX: {
                    xs: 1.5,
                    md: 1.5,
                },
                paddingY: {
                    xs: 0.75,
                    md: 1.13,
                },
                borderRadius: 99,
                textTransform: 'none',
                textDecoration: 'none',
                width: {
                    xs: 95,
                    md: 120,
                },
                backgroundColor: 'transparent',
                '&:hover': {
                    textDecoration: 'none',
                },
                '&::after': {
                    content: '""',
                    width: 24,
                    height: 2,
                    bottom: 0,
                    position: 'absolute',
                    borderRadius: '999px',
                    display: !isActive && 'none',
                    backgroundColor: theme.palette.backgroundPrimary.main
                }
            })}
            data-test-status={isActive}
        >
            <Text
                semibold
                variant="body3"
                mobileVariant='label2'
                color={(palette: Palette) => palette.backgroundPrimary
                }
            >
                {name}
            </Text>
        </Button>
    );
};