import { FC } from 'react';
import {
    SearchButton
} from 'components/advertisementsFilter/components/common/desktopFilter/partials/searchButton/SearchButton';
import {
    VerticalDivider
} from 'components/advertisementsFilter/components/common/desktopFilter/partials/verticalDivider/VerticalDivider';
import { Transaction } from 'components/advertisementsFilter/components/common/common/transaction/Transaction';
import {
    DesktopFilterWrapper
} from 'components/advertisementsFilter/components/common/desktopFilter/partials/wrapper/FilterWrapper';
import { Location } from 'components/advertisementsFilter/components/common/common/location/Location';
import { Category } from 'components/advertisementsFilter/components/common/common/category/Category';

import { useInputFocus } from 'components/advertisementsFilter/hooks/inputs/useInputFocus';
import {
    LastItemWrapper
} from 'components/advertisementsFilter/components/common/desktopFilter/partials/wrapper/lastItem/LastItem';
import {
    ITransaction
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import {
    ICategories
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import { IAutocompleteOption } from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';
import { useSelectNextInput } from 'components/advertisementsFilter/hooks/inputs/useSelectNextInput';
import { usePopover } from 'components/advertisementsFilter/hooks/popover/usePopover';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { EInputs } from 'components/advertisementsFilter/enums/inputs/EInputs';
import { useVerticalDivider } from 'components/advertisementsFilter/hooks/verticalDivider/useVerticalDivider';

interface IDesktopFilter {
    totalCount: number;
    locationQuery: string;
    selectedLocation: ILocation;
    categoryOptions: ICategories;
    selectedCategoryIds: number[];
    selectedCategoryNames: string;
    onCategoriesReset: () => void;
    onRedirectByFilter: () => void;
    onSearchFilterReset: () => void;
    selectedTransaction: ITransaction;
    transactionOptions: ITransaction[];
    locationOptionsInitial: ILocation[];
    onSelectCategory: (value: number) => void;
    onDeselectCategory: (value: number) => void;
    onChangeTransaction: (value: number) => void;
    onChangeLocationQuery: (value: string) => void;
    autocompleteLocationOptions: IAutocompleteOption[];
    onChangeLocation: (location: IAutocompleteOption) => void;
}

export const DesktopFilter: FC<IDesktopFilter> = ({
    locationQuery,
    categoryOptions,
    onSelectCategory,
    onChangeLocation,
    selectedLocation,
    onCategoriesReset,
    onDeselectCategory,
    transactionOptions,
    onRedirectByFilter,
    onChangeTransaction,
    selectedCategoryIds,
    selectedTransaction,
    onChangeLocationQuery,
    selectedCategoryNames,
    locationOptionsInitial,
    autocompleteLocationOptions,
}) => {
    const {
        anchorEl: anchorElTransaction,
        isOpen: isOpenTransaction,
        onOpen: onOpenTransaction,
        onClose:  onCloseTransaction
    } = usePopover();
    const {
        anchorEl: anchorElCategory,
        isOpen: isOpenCategory,
        onOpen: onOpenCategory,
        onClose: onCloseCategory
    } = usePopover();
    const {
        anchorEl: anchorElLocation,
        onOpen: onOpenLocation,
        isOpen: isOpenLocation,
        onClose: onCloseLocation
    } = usePopover();

    const {
        isNothingInFocus,
        isLocationHovered,
        isCategoryHovered,
        isLocationInFocus,
        isCategoryInFocus,
        onChangeHoverState,
        isTransactionHovered,
        isTransactionInFocus,
        onChangeInputInFocus,
    } = useInputFocus(
        isOpenLocation,
        isOpenTransaction,
        isOpenCategory,
    );

    const { onSelectNext } = useSelectNextInput(
        onOpenCategory,
        onOpenLocation,
        onOpenTransaction,
        onChangeInputInFocus,
    );

    const { first, second } = useVerticalDivider(
        isLocationHovered,
        isCategoryHovered,
        isLocationInFocus,
        isCategoryInFocus,
        isTransactionHovered,
        isTransactionInFocus,
    );

    return <DesktopFilterWrapper
        isFocused={!isNothingInFocus}
        maxWidth={720}
        height={64}
    >
        <Location
            isHovered={isLocationHovered}
            locationQuery={locationQuery}
            isInFocus={isLocationInFocus}
            isPopoverOpen={isOpenLocation}
            onOpenPopover={onOpenLocation}
            onClosePopover={() => {
                if (selectedTransaction && isLocationHovered) return;
                onCloseLocation();
            }}
            popoverAnchorEl={anchorElLocation}
            selectedLocation={selectedLocation}
            onChangeLocation={(location) => {
                onChangeLocation(location);

                if (location) onSelectNext(EInputs.TRANSACTION);
            }}
            onChangeHoverState={onChangeHoverState}
            onSetInputInFocus={onChangeInputInFocus}
            onChangeLocationQuery={onChangeLocationQuery}
            locationOptionsInitial={locationOptionsInitial}
            autocompleteOptions={autocompleteLocationOptions}
        />

        <VerticalDivider isHidden={first.isHidden}/>

        <Transaction
            isHovered={isTransactionHovered}
            isInFocus={isTransactionInFocus}
            transactions={transactionOptions}
            isPopoverOpen={isOpenTransaction}
            onOpenPopover={onOpenTransaction}
            onClosePopover={onCloseTransaction}
            popoverAnchorEl={anchorElTransaction}
            onChangeHoverState={onChangeHoverState}
            onSetInputInFocus={onChangeInputInFocus}
            selectedTransaction={selectedTransaction}
            onChangeTransaction={(transaction) => {
                onChangeTransaction(transaction);

                if (transaction) onSelectNext(EInputs.CATEGORY);
            }}
        />

        <VerticalDivider isHidden={second.isHidden}/>

        <LastItemWrapper
            isInFocus={isCategoryInFocus}
            isHovered={isCategoryHovered}
            isNothingInFocus={isNothingInFocus}
            onChangeHoverState={onChangeHoverState}
            isTextBold={!!selectedCategoryIds.length}
            inputComponent={(
                <Category
                    isInFocus={isCategoryInFocus}
                    isPopoverOpen={isOpenCategory}
                    onOpenPopover={onOpenCategory}
                    onClosePopover={onCloseCategory}
                    categoryOptions={categoryOptions}
                    popoverAnchorEl={anchorElCategory}
                    onSelectCategory={onSelectCategory}
                    onCategoriesReset={onCategoriesReset}
                    onDeselectCategory={onDeselectCategory}
                    onSetInputInFocus={onChangeInputInFocus}
                    selectedCategoryIds={selectedCategoryIds}
                    selectedCategoryNames={selectedCategoryNames}
                />
            )}
            buttonComponent={(
                <SearchButton
                    isMainFilter
                    onClick={onRedirectByFilter}
                    isTitleVisible={!isNothingInFocus}
                />
            )}
        />
    </DesktopFilterWrapper>;
};
