import { FC, useCallback, useState } from 'react';
import { Box, useMediaQuery, Theme } from '@mui/material';
import { Mobile } from 'modules/theme/components/responsive';
import {
    LocationInput
} from 'components/advertisementsFilter/components/common/common/location/partials/input/LocationInput';
import {
    LocationFullscreen
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/location/partials/content/partials/locationFullscreen/LocationFullscreen';
import { Popover } from 'components/advertisementsFilter/components/common/common/popover/Popover';
import {
    Autocomplete
} from 'components/advertisementsFilter/components/common/common/location/partials/autocomplete/Autocomplete';
import { ILocation as ILocationOption } from 'modules/api/clients/location/interfaces/location/ILocation';

interface ILocation {
    isInFocus: boolean;
    isSubFilter?: boolean;
    locationQuery: string;
    isPopoverOpen: boolean;
    onClosePopover: () => void;
    popoverAnchorEl: HTMLElement;
    selectedLocation: ILocationOption;
    autocompleteOptions: ILocationOption[];
    onOpenPopover: (target: HTMLElement) => void;
    onChangeLocationQuery: (value: string) => void;
    onChangeLocation: (location: ILocationOption) => void;
}

export const Location: FC<ILocation> = ({
    isInFocus,
    isSubFilter,
    locationQuery,
    onOpenPopover,
    isPopoverOpen,
    onClosePopover,
    popoverAnchorEl,
    onChangeLocation,
    autocompleteOptions,
    onChangeLocationQuery,
}) => {
    const [isLocationFullscreenOpened, setIsLocationFullscreenOpened] = useState(false);

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );
    const showSuggestions = !!autocompleteOptions.length;

    const onFullscreenToggle = useCallback((flag: boolean) => {
        setIsLocationFullscreenOpened(flag);
    }, []);

    const onChangeLocationAndCLoseFullscreen = useCallback((location: ILocationOption) => {
        onChangeLocation(location);
        setIsLocationFullscreenOpened(false);
    }, [onChangeLocation]);

    return <>
        <Box width={'100%'}>
            <LocationInput
                label='Lokalita'
                inputWidth='100%'
                clearButtonMargin={0}
                isInFocus={isInFocus}
                value={locationQuery}
                onClick={(event) => {
                    if (isMobile) {
                        onFullscreenToggle(true);
                    } else {
                        onOpenPopover(event);
                    }
                }}
                isSubFilter={isSubFilter}
                onChange={(value) => {
                    if (isMobile && !isLocationFullscreenOpened) {
                        onFullscreenToggle(true);
                    }

                    onChangeLocationQuery(value);
                }}
                placeholder='Zadajte okres alebo kraj ...'
                onClearLocation={() => {
                    onChangeLocation(null);
                    onChangeLocationQuery('');
                }}
            />
        </Box>

        <Mobile>
            {isLocationFullscreenOpened &&
                <LocationFullscreen
                    isWithParents
                    locationQuery={locationQuery}
                    onFullscreenToggle={onFullscreenToggle}
                    onChangeLocationQuery={onChangeLocationQuery}
                    isFullscreenOpened={isLocationFullscreenOpened}
                    autocompleteLocationOptions={autocompleteOptions}
                    onChangeLocation={onChangeLocationAndCLoseFullscreen}
                />
            }
        </Mobile>

        <Popover
            width={480}
            offsetOptions={{x: 25, y: 20}}
            onClose={onClosePopover}
            anchorEl={popoverAnchorEl}
            isOpen={!isMobile && isPopoverOpen && showSuggestions}
        >
            <Box
                minWidth={300}
                maxHeight={400}
                paddingX={isMobile && 2}
                sx={{
                    overflowY: 'scroll'
                }}

            >
                {showSuggestions &&
                    <Autocomplete
                        isWithParents
                        width={'100%'}
                        options={autocompleteOptions}
                        onChangeLocation={(value) => {
                            onChangeLocation(value);
                            onClosePopover();
                        }}
                    />
                }
            </Box>
        </Popover>
    </>;
};