import React, {FC} from 'react';
import {Box} from '@mui/material';
import {
    AdditionalServicesCarousel
} from 'components/additionalServicesList/partials/AdditionalServicesCarousel';
import {
    AdditionalServicesStatic
} from 'components/additionalServicesList/partials/AdditionalServicesStatic';
import { IAdditionalService } from 'components/additionalServicesList/interfaces/IAdditionalService';

interface IProps {
    additionalServices: IAdditionalService[]
    showMobilePagination?: boolean
}

export const AdditionalServicesList: FC<IProps> = ({ additionalServices, showMobilePagination = true }) => {
    return <>
        <Box display={{xs: 'block', lg: 'none'}}>
            <AdditionalServicesCarousel services={additionalServices} showMobilePagination={showMobilePagination} />
        </Box>

        <Box display={{xs: 'none', lg: 'block'}}>
            <AdditionalServicesStatic services={additionalServices}/>
        </Box>
    </>;
};
