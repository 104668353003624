import {FC} from 'react';
import {
    Option
} from 'components/page/homePage/components/filter/partials/content/partials/tabs/partials/switcher/partials/option/Option';
import {
    Wrapper
} from 'components/page/homePage/components/filter/partials/content/partials/tabs/partials/switcher/partials/wrapper/Wrapper';
import { IOption } from 'components/page/homePage/interfaces/filter/tabs/option/IOption';
import { EActiveTab } from 'components/page/homePage/enums/filter/tabs/EActiveTab';

interface ISwitchFilter {
    options: IOption[];
    uniformWidth?: boolean;
    activeTab: EActiveTab;
    onChange: (newTab: EActiveTab) => void;
}

export const SwitchFilter: FC<ISwitchFilter> = ({
    options,
    onChange,
    activeTab,
}) => {
    return <Wrapper>
        <>
            {options.map((option) => (
                <Option
                    key={option.value}
                    name={option.name}
                    onClick={(value) => {
                        onChange(value);
                    }}
                    value={option.value}
                    isActive={activeTab === option.value}
                />
            ))}
        </>
    </Wrapper>;
};