import {FC} from 'react';
import {Box, Grid} from '@mui/material';

interface IWrapper {
    children?: JSX.Element;
}

export const Wrapper: FC<IWrapper> = ({children}) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            data-test-id='switchFilterWrapper'
        >
            <Grid
                container
                padding={0.5}
                borderRadius='99px'
            >
                {children}
            </Grid>
        </Box>
    );
};