import { useState, useCallback, useEffect, useMemo } from 'react';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';

interface IReturn {
    options: ILocation[];
    locationQuery: string;
    selectedLocation: ILocation;
    onChangeQuery: (value: string) => void;
    onChangeLocation: (locationToChange: ILocation) => void;
}

export const useHomePageLocations = (
    baseFilterLocations: ILocation[],
): IReturn => {
    const [locationQuery, setLocationQuery] = useState('');
    const [selectedLocation, setSelectedLocation] = useState<ILocation>();
    const [options, setOptions] = useState<ILocation[]>([]);

    const onChangeLocation = useCallback((locationToChange: ILocation): void => {
        setSelectedLocation(locationToChange);

        if (locationToChange) setLocationQuery(locationToChange.name);
    }, []);

    const baseLocationMemoized = useMemo(() => {
        return baseFilterLocations;
    }, [baseFilterLocations]);

    const searchLocationOptions = useMemo(() => {
        if (!locationQuery) return baseLocationMemoized;

        return baseLocationMemoized.filter((option) =>
            option.name.toLocaleLowerCase().includes(locationQuery.toLocaleLowerCase()) ||
            option.sefName.toLocaleLowerCase().includes(locationQuery.toLocaleLowerCase()));
    }, [locationQuery, baseLocationMemoized]);

    useEffect(() => {
        setOptions(searchLocationOptions);
    }, [searchLocationOptions]);

    const onChangeQuery = useCallback((value: string) => {
        setLocationQuery(value);
    }, []);

    return {
        options,
        locationQuery,
        onChangeQuery,
        onChangeLocation,
        selectedLocation,
    };
};