import { FC } from 'react';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import { useAdvertisementsTotalCountByFilter } from 'components/advertisementsFilter/hooks/totalCount/useAdvertisementsTotalCountByFilter';
import { DesktopFilter } from 'components/advertisementsFilter/components/implementation/homePage/partials/desktop/DesktopFilter';
import { MobileFilter } from 'components/advertisementsFilter/components/implementation/homePage/partials/mobile/MobileFilter';
import {
    IInitialSearchFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import { useSearchFiltersReset } from 'components/advertisementsFilter/hooks/reset/useSearchFiltersReset';
import { useSearchFilterQuery } from 'components/advertisementsFilter/hooks/query/useSeachFilterQuery';
import { useSearchFilter } from 'components/advertisementsFilter/hooks/useSearchFilter';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';

interface IHomePageSearchFilter {
    initialSearchFilterData: IInitialSearchFilterData;
}

const DEFAULT_FILTER = {
    data: {},
    pagination: {
        limit: 20,
        skip: 0,
        order: EOrder.DEFAULT,
    }
};

export const HomePageSearchFilter: FC<IHomePageSearchFilter> = ({
    initialSearchFilterData,
}) => {
    const {
        onRedirectByFilter,
        onResetQueryFilter,
        setQueryParameter,
    } = useSearchFilterQuery(DEFAULT_FILTER, initialSearchFilterData);

    const {
        locationQuery,
        categoryOptions,
        onSelectCategory,
        onChangeLocation,
        selectedLocation,
        onCategoriesReset,
        onDeselectCategory,
        transactionOptions,
        selectedTransaction,
        selectedCategoryIds,
        onChangeTransaction,
        onSearchFilterReset,
        onChangeLocationQuery,
        selectedCategoryNames,
        locationOptionsInitial,
        selectedSubcategoryNames,
        selectedCategoryEnumNames,
        autocompleteLocationOptions,
    } = useSearchFilter(
        DEFAULT_FILTER,
        initialSearchFilterData,
        setQueryParameter,
        null,
    );

    const {
        totalCount,
        isFetching: isFetchingTotalCount
    } = useAdvertisementsTotalCountByFilter(
        selectedLocation && selectedLocation.id,
        selectedTransaction && selectedTransaction.name,
        selectedCategoryEnumNames,
    );

    const { onReset } = useSearchFiltersReset(
        onSearchFilterReset,
        onResetQueryFilter,
    );

    return <>
        <Mobile width='100%'>
            <MobileFilter
                height={64}
                totalCount={totalCount}
                onSearchFilterReset={onReset}
                locationQuery={locationQuery}
                categoryOptions={categoryOptions}
                selectedLocation={selectedLocation}
                onChangeLocation={onChangeLocation}
                onSelectCategory={onSelectCategory}
                onDeselectCategory={onDeselectCategory}
                onRedirectByFilter={onRedirectByFilter}
                transactionOptions={transactionOptions}
                selectedTransaction={selectedTransaction}
                selectedCategoryIds={selectedCategoryIds}
                onChangeTransaction={onChangeTransaction}
                isFetchingTotalCount={isFetchingTotalCount}
                selectedCategoryNames={selectedCategoryNames}
                onChangeLocationQuery={onChangeLocationQuery}
                locationOptionsInitial={locationOptionsInitial}
                selectedSubcategoryNames={selectedSubcategoryNames}
                autocompleteLocationOptions={autocompleteLocationOptions}
            />
        </Mobile>

        <Desktop>
            <DesktopFilter
                totalCount={totalCount}
                locationQuery={locationQuery}
                onSearchFilterReset={onReset}
                categoryOptions={categoryOptions}
                selectedLocation={selectedLocation}
                onChangeLocation={onChangeLocation}
                onSelectCategory={onSelectCategory}
                onCategoriesReset={onCategoriesReset}
                onDeselectCategory={onDeselectCategory}
                onRedirectByFilter={onRedirectByFilter}
                transactionOptions={transactionOptions}
                selectedTransaction={selectedTransaction}
                selectedCategoryIds={selectedCategoryIds}
                onChangeTransaction={onChangeTransaction}
                onChangeLocationQuery={onChangeLocationQuery}
                selectedCategoryNames={selectedCategoryNames}
                locationOptionsInitial={locationOptionsInitial}
                autocompleteLocationOptions={autocompleteLocationOptions}
            />
        </Desktop>
    </>;
};
