import { FC, useMemo } from 'react';
import { EActiveTab } from 'components/page/homePage/enums/filter/tabs/EActiveTab';
import {
    SwitchFilter
} from 'components/page/homePage/components/filter/partials/content/partials/tabs/partials/switcher/SwitchFilter';
import { IOption } from 'components/page/homePage/interfaces/filter/tabs/option/IOption';

interface ITabs {
    activeTab: EActiveTab;
    onChangeActiveTab: (newTab: EActiveTab) => void;
}

export const Tabs: FC<ITabs> = ({
    activeTab,
    onChangeActiveTab,
}) => {

    const options: IOption[] = useMemo(() => {
        return [
            { name: 'Nehnuteľnosti', value: EActiveTab.ADVERTISEMENTS },
            { name: 'Makléri', value: EActiveTab.AGENTS },
            { name: 'Novostavby', value: EActiveTab.NEW_BUILDINGS },
        ];
    }, []);

    return <SwitchFilter
        options={options}
        activeTab={activeTab}
        onChange={onChangeActiveTab}
    />;
};