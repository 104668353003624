import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { AdditionalServiceCard } from 'components/additionalServicesList/partials/AdditionalServiceCard';
import { IAdditionalService } from 'components/additionalServicesList/interfaces/IAdditionalService';
import {AppConfigService} from 'modules/appConfig/AppConfigService';

interface IAdditionalServicesStatic {
    services: IAdditionalService[]
}

export const AdditionalServicesStatic: FC<IAdditionalServicesStatic> = ({services}) => {

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            mb={4}
        >
            {services.map((info, index) => (
                <Grid key={`${info.url}-${index}`} item xs={6} sm={4} md={2}>
                    <AdditionalServiceCard
                        icon={info.icon}
                        name={info.name}
                        gtmLabel={info.gtmLabel}
                        url={AppConfigService.getNehnutelnostiUrl() + info.url}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
